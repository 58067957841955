@import "../../variables";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.film-journey {
    &.page-content{
        max-width: 1300px;
    }
    h2 {
        text-align: center;
        margin-bottom: 20px !important;
    }

    h3 {
        margin-top: 40px !important;
        margin-bottom: 20px !important;
    }

    p {
        white-space: pre-wrap;
        line-height: 150% !important;
    }

    .content-index {
        z-index: 2;
        width: 15%;
        min-width: 270px;
        margin-top: 40px;
        margin-bottom: 0;
        position: fixed;

        p{
            font-size: 0.9rem !important;
        }

        @media screen and (min-width: $breakpoint-medium) {
            top: 80px;
            right: 1vw;
        }

        @media screen and (max-width: $breakpoint-medium) {
            right: 20px;
            bottom: 20px;
        }
    }

    .section-link {
        text-decoration: underline;
        font-size: 0.9rem;
    }

    .text-content {
        width: 60%;
        @media screen and (max-width: $breakpoint-medium) {
            width: 100%;
        }
    }

    .zhua-jiu-imgs {
        max-width: 500px;
        width: 80%;
        margin: 0 auto 10px auto;

        @media screen and (max-width: $breakpoint-medium) {
            width: 100%;
        }

        img {
            flex: 1;
            max-width: 50%;
            object-fit: cover;
        }
    }

    .image-discription {
        text-align: center;
        margin-bottom: 10px;

        span {
            max-width: 500px;
        }
    }

    .journey-image {
        max-width: 70%;
        height: auto;
        margin: 30px auto;

        @media screen and (max-width: $breakpoint-medium) {
            max-width: 100%;
        }
    }

    #musical-draw-img {
        max-width: 40%;
    }

    .index-icon {
        z-index: 3;
        cursor: pointer;
        background-color: white;
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        overflow: hidden;
        /* Ensures the img stays within the border radius */
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);

        /* Custom shadow */
        img {
            width: 50%;
            height: 50%;
        }
    }

    .cross-icon {
        z-index: 3;
        cursor: pointer;
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 36px;
        height: 36px;

        img {
            width: 70%;
            height: 70%;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent dark background */
        z-index: 1;
        /* Make sure it's above other content */
    }

    // Customized image galery (slider)
    .image-gallery {
        max-width: 80%;

        @media screen and (max-width: $breakpoint-medium) {
            max-width: 100%;
        }

        margin: auto;
    }

    .image-gallery-slides {
        aspect-ratio: 4/3;
        position: relative;

        /* Create a positioning context for the image */
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        width: 20px;
    }

    .btn,
    [type=button],
    [type=reset],
    [type=submit],
    button {
        background-color: transparent;
        border-color: transparent;
        color: white;
    }

    .btn:hover,
    [type=button]:hover,
    [type=reset]:hover,
    [type=submit]:hover,
    button:hover {
        background-color: transparent;
        border-color: transparent;
        color: white;
    }
}