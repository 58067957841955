@use "cirrus-ui/src/cirrus-ext" as * with (
    $config: (
        excludes: (
            'ALL',
        ),
        includes: (
          'SHADOWS'
        )
    ),
);

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radley:ital@0;1&display=swap');

@import '_variables';

body {
  overflow-x: hidden; /* Prevent horizontal scrolling if the video overflows */
  color: $text-primary;
  background-color: $background-grey;
  margin: 0;
  /* TODO: need to modify this */
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.roboto-mono{
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
}

.radley-regular {
  font-family: "Radley", serif;
  font-weight: 400;
  font-style: normal;
}

.radley-regular-italic {
  font-family: "Radley", serif;
  font-weight: 400;
  font-style: italic;
}

h1,h2,h3,h4,h5,h6{
  margin: 0 !important;
}

h1{
  font-size: 3rem !important;
  @media (max-width: $breakpoint-medium) {
    font-size: 1.6rem !important;
  }
}

h2{
  font-size: 2rem !important;
  @media (max-width: $breakpoint-medium) {
    font-size: 1.5rem !important;
  }
}

h3{
  font-size: 1.2rem !important;
  @media (max-width: $breakpoint-medium) {
    font-size: 1rem !important;
  }
}

h4{
  font-size: 1rem !important;
  color: $text-secondary;
  @media (max-width: $breakpoint-medium) {
    font-size: 0.8rem !important;
  }
}

p{
  line-height: 130% !important;
  @media (max-width: $breakpoint-medium) {
    font-size: 0.95rem !important;
  }
}

a{
  color: $hyper-link !important;
  cursor: pointer;
}

span{
  display: inline-block;
  font-size: 0.75rem !important;
  line-height: 1 !important;
}


