.film-project-page{
    white-space: pre-wrap;

    p{
        max-width: 800px;
    }

    .video-embed-wrapper{
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    .react-player{
        position: absolute;
        top: 0;
        left: 0;

        iframe {
            height: 100% !important;
        }
    }
    
}