$breakpoint-medium: 768px;

$fontsize-normal:1rem;
$fontsize-small: 0.75rem;

$text-secondary: #525252;
$hyper-link: #0077b6;
$text-primary: #222222;
$background-grey: #FAFAFA;
$indicator-white: #a8a3a3;

