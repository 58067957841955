@import "../../variables";

// .nav-bar{
//     display: none !important;
// }
.video-wrapper {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* Ensures the video is clipped if necessary */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* Ensures the wrapper spans the entire viewport width */
  height: 100vh;

  @media screen and (max-width: $breakpoint-medium) {
    height: fit-content;
  }

  /* Optional: Sets wrapper height to viewport height */
  background-color: black;

  video {
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
      /* Set width to auto to maintain aspect ratio */
      object-fit: cover;
      /* Ensures the video fills the container */
    }

    @media screen and (min-width: $breakpoint-medium) {
      width: 80vw;
      /* Makes the video take the full width of the screen */
      height: auto;
      /* Keeps the aspect ratio intact */
    }
  }

  @keyframes colorPulse {
    0%,
    100% {
      color: $indicator-white;
      /* Original color */
      opacity: 0.1;
    }

    50% {
      color: $indicator-white;
      /* Lighter color */
      opacity: 0.5;
      /* Slightly faded */
    }
  }

  .scroll-indicator-container {
    position: absolute;
    bottom: 0;
    font-size: $fontsize-small;
    animation: colorPulse 8s infinite;

    /* Animation with a 2-second cycle */
    .down-arrow-icon {
      transform: scaleX(2.5);
      /* Stretch it horizontally */
    }
  }
}

.header-text {
  font-size: 5rem !important;

  @media screen and (max-width: $breakpoint-medium) {
    font-size: 3rem !important;
  }
}

.projects-section {
  h1,
  h2,
  h3 {
    margin-bottom: 20px !important;
  }

  .projects-intro {
    @media (min-width: $breakpoint-medium) {
      height: fit-content;
      margin-bottom: 20px;
      position: sticky;
      top: 120px;
    }
  }

  .projects-display {
    max-width: 530px;

    h3 {
      margin-bottom: 5px !important;
      line-height: 140%;
    }

    @media (min-width: $breakpoint-medium) {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
  }
}
.commercial-projects {
  .video-embed-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    iframe {
      height: 100% !important;
    }
  }
}
