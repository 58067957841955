@import "_variables";

.page-content{
    max-width: 1200px; /* Maximum width of the content */
    margin: 0 auto; /* Centers the content horizontally */
    padding: 100px 20px 0 20px;
    @media screen and (max-width: $breakpoint-medium) {
        padding-top: 80px;
    }
}

.content-footer-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.page-content-container{
    flex: 1;
}