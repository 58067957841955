@import '../../variables';
.nav-bar{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
    height: 60px;
    padding: 0 2rem;
    
    a{
        color: $text-primary !important;
        font-weight: normal !important;
    }
    .secondary-links{
        font-size: 0.85rem !important;
    }
    .logo{
        .logo-text{
            cursor: pointer;
            @media (max-width: $breakpoint-medium){
                font-size: 1rem;
            }
            font-size: 1.2rem;
        }
        img{
            width: 50px;
            @media (max-width: $breakpoint-medium){
                width: 40px;
            }
        }
    }
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); 
}